import styled from "styled-components";
import { TABLET_M_BP } from "@website-builder/utilities-index";

export const Wrapper = styled.section`
	display: flex;
	justify-content: center;
	${({ isTwoColumnSectionContent }) =>
		isTwoColumnSectionContent
			? `padding-left: calc(16.6% + 24px);
    justify-content: start;
  `
			: null}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		${({ isTwoColumnSectionContent }) =>
			isTwoColumnSectionContent
				? `padding-left: 0px;
    justify-content: center;
    img {
      max-width: 100%;
    }
  `
				: null}
	}
`;
