import React from "react";
import PropTypes from "prop-types";
import CloudinaryImage from "../CloudinaryImage";
/**
 * This image component renders a base64 encoded image if base64 string is passed in props,
 * else it calls the CloudinaryImage components with the given props.
 */
const Image = ({
	src = "",
	alt = "",
	srcBase64 = "",
	className = "",
	...restProps
}) => {
	return (
		<>
			{srcBase64 ? (
				<img className={className} src={srcBase64} alt={alt} />
			) : (
				<CloudinaryImage
					className={className}
					url={src}
					alt={alt}
					{...restProps}
				/>
			)}
		</>
	);
};

Image.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	srcBase64: PropTypes.string,
	className: PropTypes.string,
};

export default Image;
