import React from "react";
import { CloudinaryImage } from "@website-builder/ui/shared/elements";
import { Wrapper } from "./style";

const ImageComponent = ({ imageConfig, mobileImage, sectionName }) => {
	const isTwoColumnSectionContent = sectionName === "TwoColumnSection";

	return (
		<Wrapper isTwoColumnSectionContent={isTwoColumnSectionContent}>
			{imageConfig && (
				<CloudinaryImage
					url={imageConfig.url}
					sizes={{
						default: {
							width: imageConfig.width,
							height: imageConfig.height,
						},
					}}
					alt={imageConfig.altTag}
					quality="auto:best"
					fetchFormat="auto"
					dpr="auto"
					mobileImageUrl={mobileImage?.lazyImageUrl}
				/>
			)}
		</Wrapper>
	);
};

export default ImageComponent;
